import { initializeApollo } from 'lib/apolloClient';
import BlockMix from '@/components/BlockMix/BlockMix';

import { getGlobalData } from 'lib/global.js';
import { getEntityBlocks } from 'gql/common/blocks';
import { GetStaticPropsContext } from 'next';
import { Page, PageIdType, Post } from 'generated-graphql';
import { BRANDS_PAGES } from 'gql/pages';
import appConfig from '@/app.config';

export default function Resource({ pageBlocks }: { pageBlocks: Post }) {
  return (
    <div>
      <div>
        <BlockMix
          blocksData={pageBlocks?.editorBlocks}
          acfData={pageBlocks?.builder?.sections}
        />
      </div>
    </div>
  );
}

export async function getStaticPaths() {
  const apolloClient = initializeApollo();
  const allBrandPages = await apolloClient.query({
    query: BRANDS_PAGES,
    variables: { nameIn: ["agent3", "this-machine"] }
  });

  const data = allBrandPages?.data?.pages.nodes || [];

  return {
    paths:
      data.map((node: Page) => `/brands/${node.slug}`) || [],
    fallback: 'blocking',
  };
}

export async function getStaticProps(context: GetStaticPropsContext) {
  const { params } = context;
  const apolloClient = initializeApollo();
  const pageBlocks = await apolloClient.query({
    query: getEntityBlocks(),
    variables: {
      id: params?.slug,
      idType: PageIdType.Uri,
      asPreview: false,
    },
  });

  // Resource not found
  if (!pageBlocks?.data) {
    return {
      notFound: true,
    };
  }
  const pageProps = {
    pageBlocks: pageBlocks?.data?.page,
  };

  return await getGlobalData(pageProps, apolloClient, {
    revalidate: appConfig.revalidate
  });
}
